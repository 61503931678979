import React, { Component, useState, useEffect } from "react";
import ReactCompareImage from "react-compare-image";
import { Constants } from "../../adapters/helpers/Constants";
const DragSlider = (props) => {
    const { dragSliderTitle, beforeImage, afterImage, classNames, disclaimer } = props?.document?.fields;

    const getMobileViewAsset = (asset) => asset?.fields?.mobileImage?.fields?.asset?.fields?.file?.url || null;
    const getDesktopViewAsset = (asset) => asset?.fields?.desktopImage?.fields?.asset?.fields?.file?.url || null;

    const [beforeAsset, setbeforeAsset] = useState('');
    const [afterAsset, setafterAsset] = useState('');

    useEffect(() => {
        const handleResize = () => {
            const isMobileView = window.innerWidth <= Constants.desktopViewStart;
            isMobileView ? setbeforeAsset(getMobileViewAsset(beforeImage)) : setbeforeAsset(getDesktopViewAsset(beforeImage));
            isMobileView ? setafterAsset(getMobileViewAsset(afterImage)) : setafterAsset(getDesktopViewAsset(afterImage));
        }

        handleResize();
        window.addEventListener('resize', handleResize);

    }, [])

    const handleDrag = (e) => {
        if (typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push({
                event: 'customEvent',
                GAeventCategory: 'event_informational_action',
                GAeventAction: 'event_button_click',
                GAeventLabel: 'comparison_slider'
            });
        }
    }

    return (
        <div className={classNames}>
            {dragSliderTitle && <h2 className='dragslider-heading' dangerouslySetInnerHTML={{__html: dragSliderTitle}}/>}
                <div className="mainWrapper">
                    <div className="contentWrapper">
                        <ReactCompareImage leftImage={beforeAsset} rightImage={afterAsset} sliderLineWidth={4} 
                        sliderPositionPercentage={0.95}
                        onSliderPositionChange={(e) => handleDrag(e)}
                        leftImageCss={{'paddingLeft':'4px'}}
                        />         
                    </div>
                </div>
            {disclaimer && <p className="dragslider-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }} />}
        </div>
    ) 
}

export default DragSlider;