import {ContentConstants} from '../../adapters/helpers/Constants';
import PropTypes from 'prop-types';
import React from 'react';
import dynamic from 'next/dynamic';
const VideoBackgroundWrapper = dynamic(() => import('./VideoBackgroundWrapper'));
import Image from '../Image/Image';

export default function BackgroundWrapper({
                                backgroundAsset,
                                backgroundPosition,
                                sectionOffsetTop,
                                mainAssetOffsetTop,
                                mainAssetOffsetRight,
                                mainAssetOffsetBottom,
                                mainAssetOffsetLeft,
                                pauseLabel,
                                playLabel,
                                resetLabel,
                                openTranscriptLabel,
                                closeTranscriptLabel,
                                preloadImage,
                                desktopBackgroundImage,
                                mobileBackgroundImage,
                              children
                           }) {
    switch(backgroundAsset?.fields?.contentType) {
        case ContentConstants.cloudinaryVideo:
            return (
                <VideoBackgroundWrapper
                    backgroundAsset={backgroundAsset}
                    mainAssetOffsetTop={mainAssetOffsetTop}
                    mainAssetOffsetRight={mainAssetOffsetRight}
                    mainAssetOffsetBottom={mainAssetOffsetBottom}
                    mainAssetOffsetLeft={mainAssetOffsetLeft}
                    pauseLabel={pauseLabel}
                    playLabel={playLabel}
                    resetLabel={resetLabel}
                    openTranscriptLabel={openTranscriptLabel}
                    closeTranscriptLabel={closeTranscriptLabel}
                >
                    {children}
                </VideoBackgroundWrapper>
            );
        default:
            return (
                <Image image={backgroundAsset}
                    preloadImage={preloadImage}
                    desktopBackgroundImage={desktopBackgroundImage}
                    mobileBackgroundImage = {mobileBackgroundImage}
                    backgroundPosition={backgroundPosition}
                    backgroundOffsetTop={sectionOffsetTop}>
                    {children}
                </Image>
            );
    }
}

BackgroundWrapper.propTypes = {
    backgroundAsset: PropTypes.any,
    backgroundPosition: PropTypes.any,
    sectionOffsetTop: PropTypes.any,
    children: PropTypes.node,
    mainAssetOffsetTop: PropTypes.string,
    mainAssetOffsetRight: PropTypes.string,
    mainAssetOffsetBottom: PropTypes.string,
    mainAssetOffsetLeft: PropTypes.string,
};

BackgroundWrapper.defaultProps = {
    backgroundAsset: undefined,
    backgroundPosition: 0,
    sectionOffsetTop: 0,
    children: null,
};
