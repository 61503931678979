import React from 'react';
import PropTypes from 'prop-types';
import ArticleBlock from '../../adapters/helpers/ArticleBlock';
import BiographyContent from '../BiographyContent/BiographyContent';

export default function ArticleBody(props) {
    const extraAttributes = props.extraAttributes || {};
    const article = extraAttributes.entity;

    const articleBlock = new ArticleBlock({document: props.document, article });

    const children = articleBlock.renderChildBlocks({ extraAttributes }, props.onClickCallback, props.customEvent);
    const biographyContent = articleBlock?.article?.biographyContent?.fields || null;
    const biographyModalArticleList = articleBlock?.article?.articleListsForBiographyModal || null;

    return (
        <div className='ob-article-body'>
            {children}
            <React.Fragment>{biographyContent && <BiographyContent biographyContent={biographyContent} biographyModalArticleList={biographyModalArticleList} />}</React.Fragment>
        </div>
    );
}

ArticleBody.propTypes = {
    className: PropTypes.string,
    extraAttributes: PropTypes.object,
    document: PropTypes.object,
    onClickCallback:  PropTypes.func,
    customEvent: PropTypes.object
};
