import React, { Component } from "react";
import PropTypes from "prop-types";
import { localStorageRemoveItem, localStorageGetItem, localStorageSetItem } from '../../persistence/localStorage';
import AudioSwitch from "./AudioSwitch";
import VideoPlayerFactory from "../../adapters/helpers/VideoPlayerFactory";
import { VideoPlayerConstants, FileTypeConstants, ContentConstants } from "../../adapters/helpers/Constants";
import { documentGetElementById, documentGetElementByClassName, getFileExtensions } from '../../adapters/helpers/Utils';
import { Cloudinary } from "cloudinary-core";


class VideoPlayerAudioDescription extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.videoPlayer = null;
    this.video = props.video;
    this.state = {
      video: null,
    };
  }

  componentDidMount() {
    require('cloudinary-video-player');
    let soundPlayed = false;
    if (this.videoIsDefined()) {
      const videoPlayerFactory = new VideoPlayerFactory(
        this.videoRef,
        this.video
      );
      this.createVideoPlayer(videoPlayerFactory);
    }
    const cld = new Cloudinary({ cloud_name: "OralB" });

    const videoPlayer = cld.videoPlayer("player", {
      muted: false,
      controls: true
    });

    function removeSoundFromLocalStorage() {
      localStorageRemoveItem(VideoPlayerConstants.firstAudio);
      localStorageRemoveItem(VideoPlayerConstants.secondAudio);
    }

    let videoFile = videoPlayer.videojs;
    let ratioVolume = VideoPlayerConstants.videoRatioVolume;
    let firstAudiofile = documentGetElementById(VideoPlayerConstants.firstAudioFileId);
    let secondAudiofile = documentGetElementById(VideoPlayerConstants.secondAudioFileId);
    let audioDescriptionIcon = documentGetElementByClassName(VideoPlayerConstants.audioDescriptionClassName);
    removeSoundFromLocalStorage();
    let soundTrack = new Audio();
    soundTrack.autoplay = true;
    soundTrack.src = '';
    soundTrack.volume = Math.min(videoFile.volume() + ratioVolume, 1);
    function playSound(src = '', id = '') {
      soundTrack.src = src;
      soundTrack.id = id;
      soundTrack.currentTime = videoFile.currentTime();
      soundTrack.play();
    }

    function endSound() {
      soundPlayed = false;
      soundTrack.src = null;
      soundTrack.pause();
    }

    function isFirstAudio() {
      return soundTrack.id === VideoPlayerConstants.firstAudioFileId;
    }

    function pauseSound() {
      let firstAudioActive = isFirstAudio();
      soundTrack.pause();
      localStorageSetItem(firstAudioActive ? VideoPlayerConstants.firstAudio : VideoPlayerConstants.secondAudio, ContentConstants.pause);
      localStorageRemoveItem(firstAudioActive ? VideoPlayerConstants.secondAudio : VideoPlayerConstants.firstAudio);
    }

    if (firstAudiofile) {
      let ADButton = videoPlayer?.videojs?.controlBar?.addChild('button')
      ADButton.el_.classList.add(VideoPlayerConstants.audioDescriptionClassName);
      if (audioDescriptionIcon && audioDescriptionIcon.length > 0) {
        audioDescriptionIcon[0].style.opacity = VideoPlayerConstants.descriptionButtonLowOpacity
      }
      firstAudiofile.volume = ratioVolume;
      const ADButtonEvent = () => {
        if (videoFile.paused()) return;
        if (isFirstAudio()) {
          secondAudiofile.volume = Math.min(videoFile.volume() + ratioVolume, 1)
          if (audioDescriptionIcon && audioDescriptionIcon.length > 0) {
            audioDescriptionIcon[0].style.opacity = VideoPlayerConstants.descriptionButtonHighOpacity
          }
          playSound(secondAudiofile.children[0].src, secondAudiofile.id);
        } else {
          firstAudiofile.volume = Math.min(videoFile.volume() + ratioVolume, 1)
          playSound(firstAudiofile.children[0].src, firstAudiofile.id);
          if (audioDescriptionIcon && audioDescriptionIcon.length > 0) {
            audioDescriptionIcon[0].style.opacity = VideoPlayerConstants.descriptionButtonLowOpacity
          }
        }
      }
      ADButton.on('click', function (e) {
        ADButtonEvent();
      })
      ADButton.on('touchstart', function (e) {
        ADButtonEvent();
      })
      ADButton.on('touchend', function (e) {
        e.preventDefault();
      })
      videoFile.on('playing', function () {
        if (localStorageGetItem(VideoPlayerConstants.firstAudio) === ContentConstants.pause || localStorageGetItem(VideoPlayerConstants.secondAudio) == ContentConstants.pause) {
          localStorageGetItem(VideoPlayerConstants.firstAudio) === ContentConstants.pause ? playSound(firstAudiofile.children[0].src, firstAudiofile.id)
            : playSound(secondAudiofile.children[0].src, secondAudiofile.id);
          removeSoundFromLocalStorage();
          return;
        }
        if (soundPlayed) return;
        soundPlayed = true;
        playSound(firstAudiofile.children[0].src, firstAudiofile.id);
      });

      videoFile.on('pause', function () {
        pauseSound();
      });

      videoFile.on('ended', function () {
        videoFile.pause();
        endSound();
      });

      videoFile.on('volumechange', function () {
        soundTrack.volume = videoFile.volume();
        soundTrack.muted = false;
        if (videoFile.muted()) {
          soundTrack.muted = true;
        }
      });

      videoFile.on('seeking', function () {
        if (soundTrack.readyState >= 4) {
          if (Math.ceil(soundTrack.currentTime) != Math.ceil(this.currentTime())) {
            soundTrack.currentTime = this.currentTime();
          }
        }
      });
    }
  }

  createVideoPlayer(videoPlayerFactory) {
    const subTitles = this.props.video?.subTitles?.map((subtitle) => ({
      label: subtitle?.fields?.label,
      language: subtitle?.fields?.language,
      url: subtitle?.fields?.url,
    }));
    let textTracks = {
      captions: {
        label: this.props.video?.captions?.fields?.label,
        default: this.props.video?.captions?.fields?.default,
        language: this.props.video?.captions?.fields?.language,
        url: this.props.video?.captions?.fields?.url,
      },
      subtitles: subTitles && [...subTitles],
    };
    textTracks = subTitles && this.props.video?.captions && textTracks;
    const source = textTracks
      ? { sourceTypes: ["webm", "mp4", "hls", "ogg"], textTracks: textTracks }
      : { sourceTypes: ["webm", "mp4", "hls", "ogg"] };
    videoPlayerFactory
      .create()
      .then(({ videoPlayer, transformation }) => {
        const video = videoPlayer.Cloudinary.new({ secure: true })
          .videoPlayer(this.videoRef.current, {
            loop: this.video.loop,
            fontFace: "inherit",
            fluid: true,
            preload: this.video.preload ? 'auto' : 'none',
            controls: this.video.controls,
            autoplayMode: this.video.autoplay,
            playsinline: this.video.playsInline,
          })
          .transformation(transformation)
          .source(this.video?.asset?.fields?.file?.url, source);
        this.setState({
          video: video,
        });
        this.videoPlayer = video;
        this.videoPlayer.on("canplay", this.onVideoLoaded.bind(this));
        this.videoPlayer.on("play", this.onVideoPlay.bind(this));
        this.videoPlayer.on("pause", this.onVideoPause.bind(this));
        this.videoPlayer.on("mute", this.onVideoMuted.bind(this));
        this.videoPlayer.on("unmute", this.onVideoUnMuted.bind(this));
        this.videoPlayer.on(
          "fullscreenchange",
          this.onVideoFullScreen.bind(this)
        );
      })
      .catch((error) => { console.error(error) });
  }

  videoIsDefined() {
    return this.video && this.video?.asset?.fields?.file?.url;
  }

  renderTranscript() {
    return (
      <div className="video-player-transcript visuallyhidden">
        {this.video?.transcript}
      </div>
    );
  }


  render() {
    const captionsTrack = this.props.video?.captions?.fields;
    const subtitlesVideo = this.props.video?.subTitles;
    const subTitleTrack = subtitlesVideo?.[0]?.fields || null;
    const name = this.props.video?.keyframe?.fields?.asset?.fields?.title;
    const description = this.props.video?.keyframe?.fields?.asset?.fields
      ?.description;
    const thumbnailurl = this.props.video?.keyframe?.fields?.asset?.fields?.file
      ?.url;
    const UploadDate = this.props.video?.uploadDate;
    const duration = this.props.video?.duration;
    const width = this.props.video?.videoSettings?.fields?.width;
    const height = this.props.video?.videoSettings?.fields?.height;
    const contenturl = this.props.video?.asset?.fields?.file?.url;
    const ariaLabel = this.props.video?.ariaLabel;

    return (
      <div className="ob-video-player videoAudioDescription">
        <video
          muted
          playsInline
          ref={this.videoRef}
          id="player"
          aria-label={ariaLabel}
          crossOrigin="anonymous"
        >
          <source src={'https:' + this.video?.asset?.fields?.file?.url} type="video/mp4" />
          {captionsTrack && <track label={captionsTrack?.label} kind="captions" srcLang={captionsTrack?.language} src={captionsTrack?.url} default={captionsTrack?.default}></track>}
          {subTitleTrack && <track label={subTitleTrack?.label} kind="subtitles" srcLang={subTitleTrack?.language} src={subTitleTrack?.url} default={subTitleTrack?.default}></track>}
        </video>
        <AudioSwitch idValue={VideoPlayerConstants.firstAudioFileId} audioSrc={this.video?.videoAudio?.fields?.file?.url} audioFileType={FileTypeConstants[getFileExtensions(this.video?.videoAudio?.fields?.file?.url)] || 'audio/mp4'} />
        <AudioSwitch idValue={VideoPlayerConstants.secondAudioFileId} audioSrc={this.video?.videoAudioDescription?.fields?.file?.url} audioFileType={FileTypeConstants[getFileExtensions(this.video?.videoAudioDescription?.fields?.file?.url)] || 'audio/mp4'} />
        {this.renderTranscript()}
      </div>
    );
  }
}

VideoPlayerAudioDescription.propTypes = {
  video: PropTypes.object,
  onVideoPlaying: PropTypes.func,
};

export default VideoPlayerAudioDescription;