import { customJsonstringify } from '../../../adapters/helpers/Utils';
const mixtureGraphQLAPI = process.env.MIXTURE_CATALOG_GRAPHQL_API;
const mixtureChannelId = process.env.MIXTURE_CHANNEL_ID;
const mixtureCampaignId = process.env.MIXTURE_CHAMPAIGN_ID;

const CATALOG_QUERY = `
{
    campaigns(campaignIds: [${mixtureCampaignId}]) {
           campaignId
           startDate
           name {
                locale 
                value
            }
           consumerActions {
               trait {
                   id
                   name
                   desc
               }
               optIn {
                   id
                   text {
                       locale
                       value
                   }
               }
           }
        }
 }
`;

export const mixtureGraphQL = async () => {
    try {
        const response = await fetch(mixtureGraphQLAPI, {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "channelId": mixtureChannelId
            },
            body: customJsonstringify({ query: CATALOG_QUERY })
          });
          
        if(!response.ok) {
            throw new Error('error')
        }
        const data = await response.json();
        return data?.data?.campaigns?.length > 0 && data?.data?.campaigns[0];        
    } catch (error) {
        throw error;
    }    
} 
