import { Block,
    SpotlightWaitlistConstants,
    CampaignIframeOverlayConstants
   } from './exportComponent';   

const ContentProperty = (entity) => {
    
    const block = new Block(entity);
    const emailExceedLimitLabel = block.getFieldValue(SpotlightWaitlistConstants.emailLimitExceedLabel)?.fields?.text;
    const invalidLocaleLabel = block.getFieldValue(SpotlightWaitlistConstants.invalidLocaleLabel)?.fields?.text;
    const serviceUnavailableLabel = block.getFieldValue(SpotlightWaitlistConstants.serviceUnavailableLabel)?.fields?.text;
    const countrySelectLabel = block.getFieldValue(SpotlightWaitlistConstants.countrySelectLabel);
    const countrySelectList = block.getFieldValue(SpotlightWaitlistConstants.countrySelectList);
    const countrySelectListDefaultOption = block.getFieldValue(SpotlightWaitlistConstants.countrySelectListDefaultOption);
    const countrySelectErrorMessage = block.getFieldValue(SpotlightWaitlistConstants.countrySelectErrorMessage);
    const countrySelectMention = block.getFieldValue(SpotlightWaitlistConstants.countrySelectMention);
    const addressTypeCode = block.getFieldValue(SpotlightWaitlistConstants.addressTypeCode);
    const registerFailedError = block.getFieldValue(SpotlightWaitlistConstants.registerFailedError)?.fields?.text;
    const isImplicitTCAgree = block.getFieldValue(SpotlightWaitlistConstants.implicitTCAgree) || false;
    const isInterstitialPopup = block.getFieldValue(SpotlightWaitlistConstants.isInterstitialPopup);
    const rawTopicList = block.getFieldValue(SpotlightWaitlistConstants.topicList);
    const descriptionFormFieldText = block.getFieldValue(SpotlightWaitlistConstants.descriptionFormFieldText);
    const enrichmentSubCopy = block.getFieldValue(SpotlightWaitlistConstants.enrichmentSubCopy);
    const backgroundColor = block.getFieldValue(SpotlightWaitlistConstants.backgroundColor);
    const checkBoxText =  block.getFieldValue(SpotlightWaitlistConstants.checkBoxText);
    const expiryHours = block.getFieldValue(CampaignIframeOverlayConstants.expiryHours);
    const title = block.getFieldValue(SpotlightWaitlistConstants.title);
    const body = block.getFieldValue(SpotlightWaitlistConstants.body);
    const ctaLabel = block.getFieldValue(SpotlightWaitlistConstants.callToActionLabel);
    const legalText = block.getFieldValue(SpotlightWaitlistConstants.legalText);
    const formLabels = block.getFieldValue(SpotlightWaitlistConstants.formLabels);
    const classNames = block.getFieldValue(SpotlightWaitlistConstants.classNames);
    const anchorId = block.getFieldValue(SpotlightWaitlistConstants.anchorId);
    const backgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.backgroundAsset);
    const mobileBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.mobileBackgroundAsset);
    const thankYouScreenLogo = block.getFieldValue(SpotlightWaitlistConstants.thankYouScreenLogo);
    const thankYouTitleLabel = block.getFieldValue(SpotlightWaitlistConstants.thankYouTitleLabel)?.fields?.text;
    const thankYouBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.thankYouBackgroundAsset);
    const emailDisclaimerTop = block.getFieldValue(SpotlightWaitlistConstants.emailDisclaimerTop);
    const thankYouDescriptionLabel = block.getFieldValue(SpotlightWaitlistConstants.thankYouDescriptionLabel)?.fields?.text;
    const closeModalLabel = block.getFieldValue(SpotlightWaitlistConstants.closeModalLabel)?.fields?.text;
    const screen2MobileBackgroundAsset = block.getFieldValue(SpotlightWaitlistConstants.mobileBackgroundAssetSecondScreen);
    const thankYouCallToAction = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToAction);
    const thankYouCallToActionLink = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToActionLink);
    const thankYouCallToActionIconName = block.getFieldValue(SpotlightWaitlistConstants.thankYouCallToActionIconName);
    const numberOfErrorsLabel = block.getFieldValue(SpotlightWaitlistConstants.numberOfErrorsLabel);
    const modalLabelFirst = block.getFieldValue(SpotlightWaitlistConstants.modalLabelFirst);
    const modalLabelSecond = block.getFieldValue(SpotlightWaitlistConstants.modalLabelSecond);
    const alternateThankYouTitleLabel = block.getFieldValue(SpotlightWaitlistConstants.alternateThankYouTitleLabel)?.fields?.text;
    const alternateThankYouDescriptionLabel = block.getFieldValue(SpotlightWaitlistConstants.alternateThankYouDescriptionLabel)?.fields?.text;
    const duration = 600;
    const callToPreOrderActionLabel = block.getFieldValue(SpotlightWaitlistConstants.callToPreOrderActionLabel);
    const callToPreOrderActionLink = block.getFieldValue(SpotlightWaitlistConstants.callToPreOrderActionLink);
    const disclaimerWithoutCheckbox = block.getFieldValue(SpotlightWaitlistConstants.disclaimerWithoutCheckbox);

        
    return {
        emailExceedLimitLabel,
        invalidLocaleLabel,
        serviceUnavailableLabel,
        countrySelectLabel,
        countrySelectList,
        countrySelectListDefaultOption,
        countrySelectErrorMessage,
        countrySelectMention,
        addressTypeCode,
        registerFailedError,
        isImplicitTCAgree,
        isInterstitialPopup,
        rawTopicList,
        descriptionFormFieldText,
        enrichmentSubCopy,
        backgroundColor,
        checkBoxText,
        expiryHours,
        title,
        body,
        ctaLabel,
        legalText,
        formLabels,
        classNames,
        anchorId,
        backgroundAsset,
        mobileBackgroundAsset,
        thankYouScreenLogo,
        thankYouTitleLabel,
        thankYouBackgroundAsset,
        emailDisclaimerTop,
        thankYouDescriptionLabel,
        closeModalLabel,
        screen2MobileBackgroundAsset,
        thankYouCallToAction,
        thankYouCallToActionLink,
        thankYouCallToActionIconName,
        numberOfErrorsLabel,
        modalLabelFirst,
        modalLabelSecond,
        alternateThankYouTitleLabel,
        alternateThankYouDescriptionLabel,
        duration,
        callToPreOrderActionLabel,
        callToPreOrderActionLink,
        disclaimerWithoutCheckbox
    }
}

export default ContentProperty;