export const AnalyticsConstants = Object.freeze({
    collapseText: 'Collapse Link',
    expandText: 'Expand Link',
    learnMoreText: 'Learn More',
    countrySelectorText: 'Open Country Selector Panel',
    openVideoText: 'Open Video',
    buttonPriceText: 'Open Price Panel',
    contentVideoOverlayText: 'Outside Panel',
    videoPlayText: 'Play',
    videoPauseText: 'Pause',
    videoMuteText: 'Sound: Off',
    videoUnmuteText: 'Sound: On',
    videoCloseCaptionsOnText: 'Caption: On',
    videoCloseCaptionsOffText: 'Caption: Off',
    videoFullscreenOnText: 'Full Screen: On',
    videoFullscreenOffText: 'Full Screen: Off',
    dropdownMenu: 'Dropdown Menu',
    rotationView: '360 View',
    facebook: 'Facebook',
    twitter: 'Twitter',
    openLiveChat: 'chat_start_proactive',
    expandFaq: 'Expand FAQ',
    previousPage: 'Previous Page',
    nextPage: 'Next Page',
    promoRedeem: 'Redeem: '
});