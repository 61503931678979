import React from "react";
import dynamic from 'next/dynamic';
import DOMPurify from "isomorphic-dompurify";
const YoutubeVideo = dynamic(() => import('../YoutubeVideo/YoutubeVideo'));
const Icon = dynamic(() => import('../Icon/Icon'));
const MarkdownText = dynamic(() => import('../MarkdownText/MarkdownText'));

const AccordionItem = ({
  showDescription,
  ariaExpanded,
  fontWeightBold,
  item,
  index,
  onClick,
  accordionId,
}) => {

  const accordionVideo = item?.fields?.accordionVideo || null;
  let firstTab = '';

  return <div className="ob-accordion--topic" key={item.fields.accordionTitle}>
    <dt>
      <button
        aria-expanded={ariaExpanded}
        aria-controls={accordionId ? `${accordionId}-ob-accordion${index + 1}_desc` : `ob-accordion${index + 1}_desc`}
        data-qa="ob-accordion__question-button"
        className={`ob-accordion__question-button event_button_click ${fontWeightBold} ${firstTab}`}
        data-action-detail={'faq_module'}
        onClick={onClick}
        id={accordionId ? `${accordionId}-ob-accordion-${index + 1}` : `ob-accordion-${index + 1}`}
      >
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.fields.accordionTitle) }}></span>
        {ariaExpanded === 'true' ? <Icon name={'chevronDown'} size='1.8' color='#0051AD' arialable={'downArrowIcon'} /> : <Icon name={'chevronTop'} size='1.8' color='#0051AD' arialable={'topArrowIcon'} />}
      </button>
    </dt>
    <dd>
      <div id={accordionId ? `${accordionId}-ob-accordion${index + 1}_desc` : `ob-accordion${index + 1}_desc`}
        data-qa="ob-accordion__desc"
        className={`ob-accordion__desc ${showDescription}`} >
        <div>
          <MarkdownText
            className={'ob-accordion-topic-desc'}
            value={item.fields.accordionContent}
          />
          {item?.fields?.disclaimer && <MarkdownText
            className={'ob-accordion-topic-disclaimer'}
            value={item?.fields?.disclaimer}
          />}
          {accordionVideo && accordionVideoPosition == 'Bottom' && <YoutubeVideo document={accordionVideo} />}
          {item?.fields?.readMoreLabel && item?.fields?.readMoreLink && <span>
            <a href={item.fields.readMoreLink} className="ob-accordion-readmore">{item.fields.readMoreLabel}</a>
          </span>}
        </div>
      </div>
    </dd>
  </div>
}

export default AccordionItem;