class VideoPlayerFactory {

  constructor(reactRef, video) {
    this.video = video;
    this.initSettings();
    this.videoRef = reactRef;
  }

  initSettings() {
    require('cloudinary-video-player');

    this.settings = this.video.videoSettings;

    // If no settings then we use auto for quality and format.
    if (this.settings == null) {
      this.settings = {fields : {
          quality: 'auto',
          videoFormat: 'auto'
        }};
    }

    const hasSize = this.settings.fields.width != null || this.settings.fields.height != null;

    // Provide a default crop if sizes are provided.
    if (hasSize && this.settings.fields.crop == null) {
      this.settings.fields.crop = 'limit';
    }
  }

  getSettings() {
    return this.settings;
  }

  getVideo() {
    return this.video;
  }

  getVideoRef() {
    return this.videoRef;
  }

  async create() {
    let transformation = {
      video_codec: this.settings.fields.videoFormat,
      quality: this.settings.fields.quality,
    };

    if (this.settings.fields.crop != null) {
      transformation.crop = this.settings.fields.crop;
    }

    if (this.settings.fields.width != null) {
      transformation.width = this.settings.fields.width;
    }

    if (this.settings.fields.height != null) {
      transformation.height = this.settings.fields.height;
    }

    let videoPlayer = {};
      await import('cloudinary-core').then(cloudinary =>
          videoPlayer =  cloudinary
      );

    return {videoPlayer, transformation};
  }

  buildPlayerConfig() {
      let config = {
          muted: this.video.muted,
          loop: this.video.loop,
          fontFace: 'inherit',
          fluid: true,
          preload: this.video.preload ? true : false,
          controls: this.video.controls,
          autoplayMode: this.video.autoplay,
          playsinline: this.video.playsInline
      };

      if(this.video.keyframe?.fields?.assetId) {
          config['posterOptions'] = {publicId: this.video?.keyframe?.fields?.assetId};
      }

      return config;
  }
}

export default VideoPlayerFactory;
